// in src/JobOffer/CloneJobOffer.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LocalUrlField extends Component {

    handleClick = () => {
        let record = this.props.record;
        window.open(`https://${record.url}`, '_blank');
    }

    render() {

        let LinkStyle = {
            color:  '#3f51b5',
            textDecoration: 'none',
            cursor: 'pointer',
            background: 'none',
            border: 0,
            padding: 0
        };

        if(this.props.record) {
            return <button style={LinkStyle} onClick={this.handleClick}>{this.props.record.url}</button>;
        }
        else {
            return null;
        }
    }
}

LocalUrlField.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(LocalUrlField);